
import { SetupContext, defineComponent, PropType } from 'vue'
import {
  DEMAND_PRIORITIES,
  DEMAND_STATES,
  defaultSearchDemand,
  SearchDemand,
} from '@/model/Demand'

export default defineComponent({
  name: `SearchDemandForm`,
  components: {},
  props: {
    demand: {
      type: Object as PropType<SearchDemand>,
      required: false,
      default: () => defaultSearchDemand,
    },
  },
  emits: ['update', 'search', 'reset'],
  setup(
    props: Readonly<{
      demand: SearchDemand
    }>,
    context: SetupContext,
  ) {
    const search = () => {
      context.emit('search')
    }
    const reset = () => {
      context.emit('reset')
    }
    const update = (field: keyof SearchDemand, value: any) => {
      context.emit('update', { field, value })
    }

    const priorities = DEMAND_PRIORITIES
    const states = DEMAND_STATES

    return { update, search, reset, priorities, states }
  },
})
