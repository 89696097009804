export const showNotification = () => {
  if (!window.Notification) {
    console.log('Browser does not support notifications.')
  } else {
    if (Notification.permission === 'granted') {
      // const notify = new Notification('Hi there!', {
      //   body: 'How are you doing?',
      //   icon: 'https://bit.ly/2DYqRrh',
      // })
    } else {
      Notification.requestPermission()
        .then((p) => {
          if (p === 'granted') {
            // const notify = new Notification('Hi there!', {
            //   body: 'How are you doing?',
            //   icon: 'https://bit.ly/2DYqRrh',
            // })
          } else {
            console.log('User blocked notifications.')
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
}
