import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-9c0b4522")
const _hoisted_1 = { class: "filter-demand" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
      return (_openBlock(), _createElementBlock("dig-ui-badge", {
        key: filter.name,
        class: _normalizeClass({ active: filter.active })
      }, [
        _createElementVNode("span", {
          class: "name",
          onClick: ($event: any) => (_ctx.toggleActive(filter))
        }, _toDisplayString(_ctx.$t('Demand.' + filter.name)), 9, _hoisted_2),
        _createElementVNode("span", {
          class: "flow",
          onClick: ($event: any) => (_ctx.toggleFlow(filter))
        }, [
          (filter.flow === 'normal')
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "👇"))
            : _createCommentVNode("", true),
          (filter.flow === 'reverse')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, "👆"))
            : _createCommentVNode("", true)
        ], 8, _hoisted_3)
      ], 2))
    }), 128))
  ]))
}