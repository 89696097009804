
import { SetupContext, defineComponent, PropType } from 'vue'
import {
  Demand,
  defaultDemand,
  DEMAND_PRIORITIES,
  DEMAND_STATES,
  DEMAND_NATURES,
} from '@/model/Demand'
import { Project } from '@/model/Project'

export default defineComponent({
  name: `DemandForm`,
  components: {},
  props: {
    demand: {
      type: Object as PropType<Demand>,
      required: false,
      default: () => defaultDemand,
    },
    project: {
      type: Object as PropType<Project>,
      required: false,
      default: () => {
        return {
          name: 'Vous',
        }
      },
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    createForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update'],
  setup(
    props: Readonly<{
      demand: Demand
      project: Project
      isAdmin: boolean
      createForm: boolean
    }>,
    context: SetupContext,
  ) {
    const update = (field: keyof Demand, value: any) => {
      context.emit('update', { field, value })
    }

    const priorities = DEMAND_PRIORITIES
    const states = DEMAND_STATES
    const natures = DEMAND_NATURES

    return { update, priorities, states, natures }
  },
})
