
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  onBeforeUpdate,
  Ref,
} from 'vue'
import { useRoute } from 'vue-router'

import CardDemand from '@/components/core/demand/CardDemand.vue'
import SearchDemandForm from '@/components/core/demand/SearchDemandForm.vue'
import FilterDemandForm from '@/components/core/demand/FilterDemandForm.vue'
import DemandForm from '@/components/core/demand/DemandForm.vue'
import {
  StoreActionsDemand,
  storeDemand,
  StoreGettersDemand,
} from '@/store/demand'
import {
  StoreActionsProject,
  StoreGettersProject,
  storeProject,
} from '@/store/project'
import { Toaster } from '@/common/Toaster'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { USER_ROLES } from '@/model/User'
import {
  SearchDemand,
  Demand,
  defaultDemand,
  defaultFilterDemand,
  defaultSearchDemand,
  FilterDemand,
  sortDemand,
  searchDemand,
} from '@/model/Demand'
import { DigInputController } from '@digithia/input/common'
import { getUserBrowser, getUserOS } from '@/common/Utils'

export default defineComponent({
  name: `ViewRequests`,
  components: { CardDemand, FilterDemandForm, DemandForm, SearchDemandForm },
  props: {},
  emits: [],
  setup() {
    const loading = ref(false)
    const route = useRoute()
    const demands = computed(() => {
      return sortDemand(searchedDemands.value, filters.value)
    })
    const allDemands = computed(() => {
      return storeDemand.getters[StoreGettersDemand.DEMANDS]
    })
    const searchedDemands: Ref<Demand[]> = ref(allDemands.value)
    const project = computed(
      () => storeProject.getters[StoreGettersProject.PROJECT],
    )
    const isAdmin = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const projectId = ref(0)
    const searchForm = ref({
      ...storeDemand.getters[StoreGettersDemand.SEARCH_DEMAND],
    })
    const demandToCreate = ref({ ...defaultDemand })
    const filters = ref([...defaultFilterDemand])
    const elementsPerPage = ref(25)
    const currentPage = ref(1)

    const cardDemands: Ref<typeof CardDemand[]> = ref([])

    onBeforeUpdate(() => {
      cardDemands.value = []
    })

    onMounted(async () => {
      projectId.value = +route.params.id
      setDefaultTab()

      loading.value = true
      await storeProject.dispatch(StoreActionsProject.GET, projectId.value)
      await storeDemand.dispatch(StoreActionsDemand.SEARCH, {
        projectId: projectId.value,
      })
      loading.value = false
      searchedDemands.value = allDemands.value

      demandToCreate.value.browser = getUserBrowser()
      demandToCreate.value.os = getUserOS()
      demandToCreate.value.version = project.value.version

      validateSearch()
    })

    const changeTab = (event: CustomEvent) => {
      localStorage.setItem('demand-selected-tab', event.detail)
    }

    const updateDemandField = async (event: {
      id: number
      field: keyof Demand
      value: never
    }) => {
      const res = await storeDemand.dispatch(
        StoreActionsDemand.UPDATE_FIELD,
        event,
      )
      if (res.success) {
        searchedDemands.value = sortDemand(
          searchedDemands.value.map((d) => {
            return d.id === event.id ? { ...d, [event.field]: event.value } : d
          }),
          filters.value,
        )
      } else Toaster.toast(res)
    }

    const setDefaultTab = () => {
      setTimeout(() => {
        const selectedTab = localStorage.getItem('demand-selected-tab')
        const tabs = document.querySelectorAll('dig-ui-tab')
        if (selectedTab) {
          tabs.forEach((tab, index) => {
            if (index === +selectedTab) {
              tab.setAttribute('selected', '')
            } else {
              tab.removeAttribute('selected')
            }
          })
        } else {
          tabs[0].setAttribute('selected', '')
        }
      })
    }

    const updateSearch = (event: {
      field: keyof SearchDemand
      value: never
    }) => {
      searchForm.value[event.field] = event.value
      searchedDemands.value = sortDemand(
        searchDemand(allDemands.value, searchForm.value),
        filters.value,
      )
    }

    const validateSearch = () => {
      searchedDemands.value = sortDemand(
        searchDemand(allDemands.value, searchForm.value),
        filters.value,
      )
    }

    const resetSearch = () => {
      searchForm.value = { ...defaultSearchDemand }
      searchedDemands.value = sortDemand(
        searchDemand(allDemands.value, searchForm.value),
        filters.value,
      )
    }

    const refreshDemands = (demand: Demand) => {
      searchedDemands.value = sortDemand(
        searchedDemands.value.map((d) => {
          return d.id === demand.id ? demand : d
        }),
        filters.value,
      )
    }

    const updateFilter = (event: {
      name: keyof Demand
      field: keyof FilterDemand
      value: never
    }) => {
      if (event.field === 'active') {
        filters.value = filters.value.map((f: FilterDemand) => {
          if (f.name === event.name) {
            f.active = true
          } else {
            f.active = false
          }
          return f
        })
      } else {
        filters.value = filters.value.map((f: FilterDemand) => {
          if (f.name === event.name) {
            f[event.field] = event.value
          }
          return f
        })
      }
    }

    const updateCreateDemande = (event: {
      field: keyof Demand
      value: never
    }) => {
      demandToCreate.value[event.field] = event.value
    }

    const reinitCreateForm = () => {
      demandToCreate.value = { ...defaultDemand }
      demandToCreate.value.browser = getUserBrowser()
      demandToCreate.value.os = getUserOS()
      demandToCreate.value.version = project.value.version
      DigInputController.resetInputsValidity('.demand-form')
    }

    const createDemand = async () => {
      const res = await storeDemand.dispatch(StoreActionsDemand.ADD, {
        ...demandToCreate.value,
        projectId: projectId.value,
      })
      Toaster.toast(res)
      if (res.success) {
        reinitCreateForm()
        refreshDemands(res.data)
      }
    }

    const showAllDetail = () => {
      for (const item of cardDemands.value) {
        item.setShowDetail(true)
      }
    }

    const hideAllDetail = () => {
      for (const item of cardDemands.value) {
        item.setShowDetail(false)
      }
    }

    return {
      loading,
      demands,
      allDemands,
      project,
      createDemand,
      resetSearch,
      demandToCreate,
      isAdmin,
      searchForm,
      refreshDemands,
      validateSearch,
      updateSearch,
      updateCreateDemande,
      reinitCreateForm,
      filters,
      updateFilter,
      changeTab,
      updateDemandField,
      elementsPerPage,
      currentPage,
      showAllDetail,
      hideAllDetail,
      cardDemands,
    }
  },
})
