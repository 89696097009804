
import { SetupContext, defineComponent, PropType } from 'vue'
import { defaultFilterDemand, FilterDemand } from '@/model/Demand'

export default defineComponent({
  name: `DemandForm`,
  components: {},
  props: {
    filters: {
      type: Object as PropType<FilterDemand[]>,
      required: false,
      default: () => defaultFilterDemand,
    },
  },
  emits: ['update'],
  setup(
    props: Readonly<{
      filters: FilterDemand[]
    }>,
    context: SetupContext,
  ) {
    const toggleActive = (filter: FilterDemand) => {
      context.emit('update', {
        name: filter.name,
        field: 'active',
        value: !filter.active,
      })
    }

    const toggleFlow = (filter: FilterDemand) => {
      let flow
      if (filter.flow === 'normal') {
        flow = 'reverse'
      } else {
        flow = 'normal'
      }
      context.emit('update', {
        name: filter.name,
        field: 'flow',
        value: flow,
      })
    }

    return { toggleActive, toggleFlow }
  },
})
