
import { defineComponent, PropType, ref, SetupContext, watch } from 'vue'
import DemandForm from '@/components/core/demand/DemandForm.vue'
import {
  Demand,
  DEMAND_PRIORITIES,
  DEMAND_STATES,
  DEMAND_NATURES,
  nextNature,
  nextPriority,
  nextState,
} from '@/model/Demand'
import { __ } from '@/locales'
import { StoreActionsDemand, storeDemand } from '@/store/demand'
import { Toaster } from '@/common/Toaster'
import { dateToCountdown, formatDatetime, downloadFile } from '@/common/Utils'
import { showNotification } from '@/common/Notification'
import { Project } from '@/model/Project'

export default defineComponent({
  name: `CardDemand`,
  components: {
    DemandForm,
  },
  props: {
    demand: {
      type: Object as PropType<Demand>,
      required: true,
    },
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAllDetail: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['updateField', 'update'],
  setup(
    props: Readonly<{
      demand: Demand
      project: Project
      isAdmin: boolean
      showAllDetail: boolean
    }>,
    context: SetupContext,
  ) {
    const showDetail = ref(
      JSON.parse(
        localStorage.getItem(`demand-show-detail-${props.demand.id}`) ||
          'false',
      ),
    )
    const editing = ref(false)
    const loading = ref(false)
    const demandForm = ref(props.demand)

    watch(
      () => props.demand,
      async () => {
        if (!props.demand) return

        demandForm.value = JSON.parse(JSON.stringify(props.demand))
      },
    )

    const setShowDetail = (value: boolean) => {
      showDetail.value = value
      localStorage.setItem(
        `demand-show-detail-${props.demand.id}`,
        JSON.stringify(showDetail.value ?? false),
      )
    }

    const toggleShowDetail = () => {
      showDetail.value = !showDetail.value
      localStorage.setItem(
        `demand-show-detail-${props.demand.id}`,
        JSON.stringify(showDetail.value ?? false),
      )
    }

    const update = (event: { field: keyof Demand; value: never }) => {
      demandForm.value[event.field] = event.value
    }

    const updateField = (field: keyof Demand, value: any) => {
      if (
        !props.isAdmin &&
        (field === 'providerChecked' || field === 'state')
      ) {
        return
      }
      context.emit('updateField', { id: props.demand.id, field, value })
    }

    const validateEdition = async () => {
      loading.value = true
      const res = await storeDemand.dispatch(StoreActionsDemand.UPDATE, {
        demand: demandForm.value,
        id: props.demand.id,
      })
      loading.value = false
      Toaster.toast(res)

      if (res.success) {
        editing.value = false
        context.emit('update', res.data)
      }
    }

    const deleteFileDemand = async (filename: string) => {
      if (confirm(__(`Demand.askDeleteFile`))) {
        const res = await storeDemand.dispatch(StoreActionsDemand.DELETE_FILE, {
          id: props.demand.id,
          filename: filename,
        })
        Toaster.toast(res)
      }
    }

    const deleteDemand = async () => {
      if (confirm(__(`Demand.askDelete`))) {
        const res = await storeDemand.dispatch(
          StoreActionsDemand.DELETE,
          props.demand.id,
        )
        Toaster.toast(res)
      }
    }

    const cancelEdition = () => {
      demandForm.value = JSON.parse(JSON.stringify(props.demand))
      editing.value = false
    }

    const downloadFileDemand = async (filename: string) => {
      Toaster.toast({ type: 'info', message: __('downloading').toString() })
      const res = await storeDemand.dispatch(StoreActionsDemand.GET_FILE, {
        id: props.demand.id,
        filename: filename,
      })
      if (res.success) {
        downloadFile(res.data, filename)
      }
    }

    const uploadFileDemand = async (event: any) => {
      Toaster.toast({ type: 'info', message: __('downloading').toString() })
      const res = await storeDemand.dispatch(StoreActionsDemand.ADD_FILE, {
        id: props.demand.id,
        base64: event.detail.base64,
        filename: event.detail.file.name,
      })

      Toaster.toast(res)
    }

    return {
      DEMAND_PRIORITIES,
      DEMAND_STATES,
      DEMAND_NATURES,
      showDetail,
      toggleShowDetail,
      editing,
      update,
      validateEdition,
      cancelEdition,
      demandForm,
      loading,
      deleteDemand,
      dateToCountdown,
      formatDatetime,
      setShowDetail,
      updateField,
      nextPriority,
      nextState,
      nextNature,
      showNotification,
      downloadFileDemand,
      uploadFileDemand,
      deleteFileDemand,
    }
  },
})
